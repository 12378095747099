import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/Personal-Website/Personal-Website/src/components/layout/MD_Layout.js";
import { Display } from "../../components/models/DanceFloor";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Display sx={{
      width: "100%"
    }} mdxType="Display" />
    <h2>{`Creating the Dance Animation`}</h2>
    <p>{`After spending three hours trying to mesh to wave in a believable way I was not about to try and get it bust a move.
`}<a parentName="p" {...{
        "href": "https://www.mixamo.com/#/"
      }}>{`Adobe Mixamo`}</a>{` has free to use models and animations, with the animations usable by uploading
your own model. So with little to no effort I had a break dancing model.`}</p>
    <h4>{`Importing the Mixamo File`}</h4>
    <p>{`Mixamo files can be downloaded as .fbx or .dae files, which is not the ideal GLTF format that we want. To
convert the file into a GLTF I chose to load the FBX file into Blender and re-download as a GLTF.`}</p>
    <p>{`Once downloaded I used the helpers provided by `}<a parentName="p" {...{
        "href": "https://github.com/pmndrs/drei#usefbx"
      }}>{`react-three-drei`}</a>{` to load the model and run the animation.`}</p>
    <h4>{`Dancer Component`}</h4>
    <p>{`All of the code can be found in the `}<a parentName="p" {...{
        "href": "https://github.com/CannonLock/Personal-Website/blob/master/src/components/models/DanceFloor.jsx"
      }}>{`DancerFloor.jsx File`}</a>{`
, but I have also included the Dancer Component below. I have removed some code specific to my implementation to make the below more general for all Mixamo models.`}</p>
    <pre><code parentName="pre" {...{}}>{`const Dancer = (props) => {
  const dancer = useRef()
  const gltf = useGLTF(DancerFile)
  const {nodes, materials, animations} = gltf
  const { ref, mixer, names, actions, clips } = useAnimations(animations, dancer)

  useEffect(() => {
    actions["Armature|mixamo.com|Layer0"].play()
  });

  return (
    <group ref={ref} dispose={null}>
      <primitive object={nodes?.mixamorigHips} />
      <skinnedMesh
        geometry={nodes?.Man__Cube004.geometry}
        skeleton={nodes?.Man__Cube004.skeleton}
      >
        <meshStandardMaterial attach={"material"} color={"black"} />
      </skinnedMesh>
    </group>
  )
}
`}</code></pre>
    <h2>{`Links`}</h2>
    <p>{`Below is a list of the various docs, and code pens that I looked at well building this. There is a lot
of cool things to think about for the future here.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://threejs.org/examples/#webgl_animation_keyframes"
        }}>{`Initial Three JS Inspiration Examples`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mixamo.com/#/?genres=Dance&page=1&type=Motion%2CMotionPack"
        }}>{`Dance Animations from Mixamo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://drei.pmnd.rs/?path=/story/performance-adaptive--adaptive-scene-st"
        }}>{`React Three Fiber Helper Storybook`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      